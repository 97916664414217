<template>
  <div class="contact-container1">
    <app-header></app-header>
    <div class="contact-container2 thq-section-padding">
      <div class="contact-max-width thq-section-max-width">
        <div class="contact-contact1 thq-section-padding">
          <div class="contact-section-title thq-flex-column">
            <span class="contact-text10 beforeHeading">Always Happy to Help</span>
            <div class="contact-content4">
              <h2 class="contact-text11 thq-heading-2">Contact Us</h2>
              <span class="contact-text12 thq-body-small">
                <span>
                  Have a question or need assistance? Fill out the form below and
                  we&apos;ll get back to you as soon as possible.
                </span>
                <span><span v-html="rawlks6"></span></span>
              </span>
            </div>
            <div class="contact-content5">
              <div class="contact-row1">
                <svg viewBox="0 0 1024 1024" class="contact-icon1 thq-icon-small">
                  <path
                    d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"
                  ></path>
                </svg>
                <span class="contact-email thq-body-small">
                  info@plotpoint.com
                </span>
              </div>
              <div class="contact-row2">
                <svg viewBox="0 0 1024 1024" class="contact-icon3 thq-icon-small">
                  <path
                    d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"
                  ></path>
                </svg>
                <a href="tel:+442081547035" class="contact-phone thq-body-small">
                  +44 208 154 7035
                </a>
              </div>
              <div class="contact-row3">
                <svg viewBox="0 0 1024 1024" class="contact-icon5 thq-icon-small">
                  <path
                    d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"
                  ></path>
                </svg>
                <span class="contact-address thq-body-small">
                  <span class="contact-text15">
                    Fourth Floor, 70 Baker Street
                  </span>
                  <br />
                  <span>London, W1U 7DL</span>
                  <br />
                </span>
              </div>
            </div>
          </div>
          <contact-form
            rootClassName="contact-formroot-class-name"
          ></contact-form>
        </div>
      </div>
    </div>
    <app-footer>
      <template v-slot:text>
        <div class="contact-fragment10">
          <span class="contact-text19">Product</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="contact-fragment11">
          <span class="contact-text20">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="contact-fragment12">
          <span class="contact-text21">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="contact-fragment13">
          <span class="contact-text22">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="contact-fragment14">
          <span class="contact-text23">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="contact-fragment15">
          <span class="contact-text24">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="contact-fragment16">
          <span class="contact-text25">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="contact-fragment17">
          <span class="contact-text26">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="contact-fragment18">
          <span class="contact-text27">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="contact-fragment19">
          <span class="contact-text28 thq-body-small">
            Your Capital is at Risk
          </span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="contact-fragment20">
          <span class="contact-text29">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="contact-fragment21">
          <span class="contact-text30">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="contact-fragment22">
          <span class="contact-text31">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="contact-fragment23">
          <span class="contact-text32">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="contact-fragment24">
          <span class="contact-content1 thq-body-small">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="contact-fragment25">
          <span class="contact-content2 thq-body-small">
            <span class="contact-text36">Phone: </span>
            <a href="tel:+442081547035" class="contact-link1">+</a>
            <a href="tel:+442081547035" class="contact-link2">44 208 154 7035</a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="contact-fragment26">
          <span class="contact-content3">
            <span class="contact-text38">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawekj5"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="contact-fragment27">
          <span class="contact-text41 thq-body-small">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import ContactForm from '../components/contact-form'
import AppFooter from '../components/footer'

export default {
  name: 'Contact',
  props: {},
  components: {
    AppHeader,
    ContactForm,
    AppFooter,
  },
  data() {
    return {
      rawlks6: ' ',
      rawekj5: ' ',
    }
  },
  metaInfo: {
    title: 'Contact Us - PlotPoint',
    meta: [
      {
        property: 'og:title',
        content: 'Contact Us - PlotPoint',
      },
    ],
  },
}
</script>

<style scoped>
.contact-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-container2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-scheme-green100);
}
.contact-max-width {
  align-self: center;
}
.contact-contact1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: flex-start;
}
.contact-section-title {
  gap: 0;
  width: 50%;
  align-items: flex-start;
  padding-right: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.contact-text10 {
  color: var(--dl-color-scheme-white);
}
.contact-content4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.contact-text11 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
}
.contact-text12 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-family: "Bricolage Grotesque";
}
.contact-content5 {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  align-items: flex-start;
  flex-direction: column;
}
.contact-row1 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-icon1 {
  fill: var(--dl-color-scheme-white);
}
.contact-email {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-family: "Bricolage Grotesque";
}
.contact-row2 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-icon3 {
  fill: var(--dl-color-scheme-white);
}
.contact-phone {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-family: "Bricolage Grotesque";
  text-decoration: none;
}
.contact-row3 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-icon5 {
  fill: var(--dl-color-scheme-white);
}
.contact-address {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-family: "Bricolage Grotesque";
}
.contact-text15 {
  font-family: "Bricolage Grotesque";
}
.contact-fragment10 {
  display: contents;
}
.contact-text19 {
  display: inline-block;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.contact-fragment11 {
  display: contents;
}
.contact-text20 {
  display: inline-block;
  margin-bottom: 0px;
}
.contact-fragment12 {
  display: contents;
}
.contact-text21 {
  display: inline-block;
}
.contact-fragment13 {
  display: contents;
}
.contact-text22 {
  display: inline-block;
}
.contact-fragment14 {
  display: contents;
}
.contact-text23 {
  display: inline-block;
}
.contact-fragment15 {
  display: contents;
}
.contact-text24 {
  display: inline-block;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.contact-fragment16 {
  display: contents;
}
.contact-text25 {
  display: inline-block;
}
.contact-fragment17 {
  display: contents;
}
.contact-text26 {
  display: inline-block;
}
.contact-fragment18 {
  display: contents;
}
.contact-text27 {
  display: inline-block;
}
.contact-fragment19 {
  display: contents;
}
.contact-text28 {
  display: inline-block;
  font-size: 23px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
.contact-fragment20 {
  display: contents;
}
.contact-text29 {
  display: inline-block;
  font-weight: 700;
  padding-bottom: var(--dl-space-space-halfunit);
}
.contact-fragment21 {
  display: contents;
}
.contact-text30 {
  display: inline-block;
}
.contact-fragment22 {
  display: contents;
}
.contact-text31 {
  display: inline-block;
}
.contact-fragment23 {
  display: contents;
}
.contact-text32 {
  display: inline-block;
}
.contact-fragment24 {
  display: contents;
}
.contact-content1 {
  display: inline-block;
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
}
.contact-fragment25 {
  display: contents;
}
.contact-content2 {
  display: inline-block;
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
}
.contact-text36 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
.contact-link1 {
  text-decoration: underline;
}
.contact-link2 {
  text-decoration: underline;
}
.contact-fragment26 {
  display: contents;
}
.contact-content3 {
  display: inline-block;
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
}
.contact-text38 {
  font-weight: 700;
}
.contact-fragment27 {
  display: contents;
}
.contact-text41 {
  display: inline-block;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  text-transform: capitalize;
}
@media(max-width: 991px) {
  .contact-contact1 {
    flex-direction: column;
  }
  .contact-section-title {
    width: 100%;
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 767px) {
  .contact-contact1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .contact-text30 {
    text-align: center;
  }
  .contact-content3 {
    text-align: center;
    padding-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .contact-container2 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .contact-text12 {
    text-align: left;
  }
  .contact-content3 {
    text-align: center;
  }
}
</style>
