<template>
  <div class="coming-soon-container1">
    <app-header></app-header>
    <div class="coming-soon-hero hero-container section-container">
      <div class="coming-soon-max-width1 max-width">
        <div class="coming-soon-content">
          <span class="coming-soon-subtitle">WE AREN&apos;T QUITE READY YET</span>
          <h1 class="coming-soon-title">
            <span>Coming </span>
            <span class="coming-soon-text11">Soon</span>
          </h1>
          <span class="coming-soon-description">
            Apologies, we are still working on launching our app and originator
            portals. We will be ready shortly, in the meantime, please contact us
            if you would like to get in touch.
          </span>
          <div class="coming-soon-container2">
            <router-link
              to="/contact"
              class="coming-soon-navlink button button-gradient"
            >
              Contact Us
            </router-link>
          </div>
        </div>
        <div class="coming-soon-image1">
          <img
            alt="image"
            src="/common/union-400h.png"
            class="coming-soon-graphic-top"
          />
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="coming-soon-image2"
          />
        </div>
      </div>
    </div>
    <div class="coming-soon-section section-container">
      <div class="coming-soon-max-width2 max-width">
        <cta-banner-both></cta-banner-both>
      </div>
    </div>
    <app-footer>
      <template v-slot:text>
        <div class="coming-soon-fragment10">
          <span class="coming-soon-text12">Product</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="coming-soon-fragment11">
          <span class="coming-soon-text13">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="coming-soon-fragment12">
          <span class="coming-soon-text14">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="coming-soon-fragment13">
          <span class="coming-soon-text15">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="coming-soon-fragment14">
          <span class="coming-soon-text16">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="coming-soon-fragment15">
          <span class="coming-soon-text17">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="coming-soon-fragment16">
          <span class="coming-soon-text18">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="coming-soon-fragment17">
          <span class="coming-soon-text19">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="coming-soon-fragment18">
          <span class="coming-soon-text20">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="coming-soon-fragment19">
          <span class="coming-soon-text21 thq-body-small">
            Your Capital is at Risk
          </span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="coming-soon-fragment20">
          <span class="coming-soon-text22">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="coming-soon-fragment21">
          <span class="coming-soon-text23">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="coming-soon-fragment22">
          <span class="coming-soon-text24">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="coming-soon-fragment23">
          <span class="coming-soon-text25">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="coming-soon-fragment24">
          <span class="coming-soon-content1 thq-body-small">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="coming-soon-fragment25">
          <span class="coming-soon-content2 thq-body-small">
            <span class="coming-soon-text29">Phone: </span>
            <a href="tel:+442081547035" class="coming-soon-link1">+</a>
            <a href="tel:+442081547035" class="coming-soon-link2">
              44 208 154 7035
            </a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="coming-soon-fragment26">
          <span class="coming-soon-content3">
            <span class="coming-soon-text31">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawge3h"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="coming-soon-fragment27">
          <span class="coming-soon-text34 thq-body-small">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import CtaBannerBoth from '../components/cta-banner-both'
import AppFooter from '../components/footer'

export default {
  name: 'ComingSoon',
  props: {},
  components: {
    AppHeader,
    CtaBannerBoth,
    AppFooter,
  },
  data() {
    return {
      rawge3h: ' ',
    }
  },
  metaInfo: {
    title: 'Coming Soon - PlotPoint',
    meta: [
      {
        property: 'og:title',
        content: 'Coming Soon - PlotPoint',
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
}
</script>

<style scoped>
.coming-soon-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.coming-soon-hero {
  padding-bottom: var(--dl-space-space-twounits);
}
.coming-soon-max-width1 {
  height: 668px;
  align-items: center;
}
.coming-soon-content {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.coming-soon-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
.coming-soon-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-unit);
}
.coming-soon-text11 {
  color: var(--dl-color-scheme-celadon);
}
.coming-soon-description {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
  padding-bottom: var(--dl-space-space-unit);
}
.coming-soon-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.coming-soon-navlink {
  text-decoration: none;
}
.coming-soon-image1 {
  position: relative;
  padding-bottom: var(--dl-space-space-fourunits);
}
.coming-soon-graphic-top {
  top: 0px;
  right: -170px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
.coming-soon-image2 {
  right: -170px;
  bottom: 0px;
  position: absolute;
  object-fit: contain;
}
.coming-soon-section {
  position: relative;
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
}
.coming-soon-max-width2 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.coming-soon-fragment10 {
  display: contents;
}
.coming-soon-text12 {
  display: inline-block;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.coming-soon-fragment11 {
  display: contents;
}
.coming-soon-text13 {
  display: inline-block;
  margin-bottom: 0px;
}
.coming-soon-fragment12 {
  display: contents;
}
.coming-soon-text14 {
  display: inline-block;
}
.coming-soon-fragment13 {
  display: contents;
}
.coming-soon-text15 {
  display: inline-block;
}
.coming-soon-fragment14 {
  display: contents;
}
.coming-soon-text16 {
  display: inline-block;
}
.coming-soon-fragment15 {
  display: contents;
}
.coming-soon-text17 {
  display: inline-block;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.coming-soon-fragment16 {
  display: contents;
}
.coming-soon-text18 {
  display: inline-block;
}
.coming-soon-fragment17 {
  display: contents;
}
.coming-soon-text19 {
  display: inline-block;
}
.coming-soon-fragment18 {
  display: contents;
}
.coming-soon-text20 {
  display: inline-block;
}
.coming-soon-fragment19 {
  display: contents;
}
.coming-soon-text21 {
  display: inline-block;
  font-size: 23px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
.coming-soon-fragment20 {
  display: contents;
}
.coming-soon-text22 {
  display: inline-block;
  font-weight: 700;
  padding-bottom: var(--dl-space-space-halfunit);
}
.coming-soon-fragment21 {
  display: contents;
}
.coming-soon-text23 {
  display: inline-block;
}
.coming-soon-fragment22 {
  display: contents;
}
.coming-soon-text24 {
  display: inline-block;
}
.coming-soon-fragment23 {
  display: contents;
}
.coming-soon-text25 {
  display: inline-block;
}
.coming-soon-fragment24 {
  display: contents;
}
.coming-soon-content1 {
  display: inline-block;
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
}
.coming-soon-fragment25 {
  display: contents;
}
.coming-soon-content2 {
  display: inline-block;
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
}
.coming-soon-text29 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
.coming-soon-link1 {
  text-decoration: underline;
}
.coming-soon-link2 {
  text-decoration: underline;
}
.coming-soon-fragment26 {
  display: contents;
}
.coming-soon-content3 {
  display: inline-block;
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
}
.coming-soon-text31 {
  font-weight: 700;
}
.coming-soon-fragment27 {
  display: contents;
}
.coming-soon-text34 {
  display: inline-block;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  text-transform: capitalize;
}
@media(max-width: 1200px) {
  .coming-soon-text11 {
    color: var(--dl-color-scheme-celadon);
  }
}
@media(max-width: 991px) {
  .coming-soon-content {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .coming-soon-image1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .coming-soon-max-width2 {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .coming-soon-max-width1 {
    height: 450px;
    flex-direction: column-reverse;
  }
  .coming-soon-content {
    width: 100%;
    margin-right: 0px;
  }
  .coming-soon-image1 {
    display: none;
  }
  .coming-soon-text23 {
    text-align: center;
  }
  .coming-soon-content3 {
    text-align: center;
    padding-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .coming-soon-max-width1 {
    height: 450px;
  }
  .coming-soon-image1 {
    display: none;
  }
  .coming-soon-image2 {
    right: -10px;
  }
  .coming-soon-content3 {
    text-align: center;
  }
}
</style>
