import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';

import PageNotFound from './views/page-not-found';
import PrivateCredit from './views/private-credit';
import About from './views/about';
import Originators from './views/originators';
import Contact from './views/contact';
import ComingSoon from './views/coming-soon';
import Disclaimer from './views/disclaimer';
import TermsConditions from './views/terms-conditions';
import PrivacyPolicy from './views/privacy-policy';
import Projects from './views/projects';
import Home from './views/home';
import './style.css';

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Private-Credit',
      path: '/private-credit',
      component: PrivateCredit,
    },
    {
      name: 'About',
      path: '/about',
      component: About,
    },
    {
      name: 'Originators',
      path: '/originators',
      component: Originators,
    },
    {
      name: 'Contact',
      path: '/contact',
      component: Contact,
    },
    {
      name: 'Coming-Soon',
      path: '/coming-soon',
      component: ComingSoon,
    },
    {
      name: 'Disclaimer',
      path: '/disclaimer',
      component: Disclaimer,
    },
    {
      name: 'Terms-Conditions',
      path: '/terms-conditions',
      component: TermsConditions,
    },
    {
      name: 'PrivacyPolicy',
      path: '/privacy-policy',
      component: PrivacyPolicy,
    },
    {
      name: 'Projects',
      path: '/projects',
      component: Projects,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'Page-Not-Found',
      path: '**',
      component: PageNotFound,
      fallback: true,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // If there is a saved scroll position (from browser back/forward), use that
    if (savedPosition) {
      return savedPosition;
    } else {
      // Scroll to the top of the page for new navigation
      return { x: 0, y: 0 };
    }
  },
});
