<template>
  <div class="terms-conditions-container">
    <app-header></app-header>
    <div class="terms-conditions-hero hero-container section-container">
      <div class="terms-conditions-max-width1 max-width">
        <div class="terms-conditions-content4">
          <span class="terms-conditions-subtitle">PRIVATE CREDIT</span>
          <h1 class="terms-conditions-title">
            <span>Funder </span>
            <span class="terms-conditions-text11">Terms &amp; Conditions</span>
          </h1>
        </div>
        <div class="terms-conditions-image"></div>
      </div>
    </div>
    <div class="terms-conditions-layout302 thq-section-padding">
      <div class="terms-conditions-max-width2 thq-section-max-width">
        <div class="terms-conditions-content5">
          <div class="terms-conditions-content6 thq-flex-column">
            <h3 class="thq-heading-3 terms-conditions-title1">
              Our Terms &amp; Conditions with our clients
            </h3>
            <a
              href="https://go.plotpoint.co.uk/funder-terms"
              target="_blank"
              rel="noreferrer noopener"
              class="terms-conditions-description1 thq-body-small"
            >
              <span class="terms-conditions-text12">
                You can download our latest Funder Terms &amp; Conditions by
                clicking here.
              </span>
              <br />
            </a>
            <iframe
              src="https://plotpoint.co.uk/downloads/plotpoint_funder_terms.pdf"
              allowfullscreen="true"
              class="terms-conditions-iframe"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <app-footer>
      <template v-slot:text>
        <div class="terms-conditions-fragment10">
          <span class="terms-conditions-text14">Product</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="terms-conditions-fragment11">
          <span class="terms-conditions-text15">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="terms-conditions-fragment12">
          <span class="terms-conditions-text16">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="terms-conditions-fragment13">
          <span class="terms-conditions-text17">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="terms-conditions-fragment14">
          <span class="terms-conditions-text18">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="terms-conditions-fragment15">
          <span class="terms-conditions-text19">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="terms-conditions-fragment16">
          <span class="terms-conditions-text20">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="terms-conditions-fragment17">
          <span class="terms-conditions-text21">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="terms-conditions-fragment18">
          <span class="terms-conditions-text22">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="terms-conditions-fragment19">
          <span class="terms-conditions-text23 thq-body-small">
            Your Capital is at Risk
          </span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="terms-conditions-fragment20">
          <span class="terms-conditions-text24">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="terms-conditions-fragment21">
          <span class="terms-conditions-text25">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="terms-conditions-fragment22">
          <span class="terms-conditions-text26">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="terms-conditions-fragment23">
          <span class="terms-conditions-text27">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="terms-conditions-fragment24">
          <span class="terms-conditions-content1 thq-body-small">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="terms-conditions-fragment25">
          <span class="terms-conditions-content2 thq-body-small">
            <span class="terms-conditions-text31">Phone: </span>
            <a href="tel:+442081547035" class="terms-conditions-link1">+</a>
            <a href="tel:+442081547035" class="terms-conditions-link2">
              44 208 154 7035
            </a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="terms-conditions-fragment26">
          <span class="terms-conditions-content3">
            <span class="terms-conditions-text33">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawhud9"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="terms-conditions-fragment27">
          <span class="terms-conditions-text36 thq-body-small">
            PlotPoint Limited
          </span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppFooter from '../components/footer'

export default {
  name: 'TermsConditions',
  props: {},
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      rawhud9: ' ',
    }
  },
  metaInfo: {
    title: 'Terms & Conditions - Yubaro',
    meta: [
      {
        property: 'og:title',
        content: 'Terms & Conditions - Yubaro',
      },
    ],
  },
}
</script>

<style scoped>
.terms-conditions-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.terms-conditions-hero {
  padding-bottom: 0px;
}
.terms-conditions-max-width1 {
  align-items: center;
}
.terms-conditions-content4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.terms-conditions-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
.terms-conditions-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-unit);
}
.terms-conditions-text11 {
  color: var(--dl-color-scheme-celadon);
}
.terms-conditions-image {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: none;
  position: relative;
  padding-bottom: var(--dl-space-space-fourunits);
}
.terms-conditions-layout302 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.terms-conditions-max-width2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.terms-conditions-content5 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.terms-conditions-content6 {
  width: 100%;
  align-items: flex-start;
}
.terms-conditions-description1 {
  text-align: left;
  font-family: "Bricolage Grotesque";
  text-decoration: none;
}
.terms-conditions-text12 {
  text-decoration: underline;
}
.terms-conditions-iframe {
  width: 100%;
  height: 600px;
}
.terms-conditions-fragment10 {
  display: contents;
}
.terms-conditions-text14 {
  display: inline-block;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.terms-conditions-fragment11 {
  display: contents;
}
.terms-conditions-text15 {
  display: inline-block;
  margin-bottom: 0px;
}
.terms-conditions-fragment12 {
  display: contents;
}
.terms-conditions-text16 {
  display: inline-block;
}
.terms-conditions-fragment13 {
  display: contents;
}
.terms-conditions-text17 {
  display: inline-block;
}
.terms-conditions-fragment14 {
  display: contents;
}
.terms-conditions-text18 {
  display: inline-block;
}
.terms-conditions-fragment15 {
  display: contents;
}
.terms-conditions-text19 {
  display: inline-block;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.terms-conditions-fragment16 {
  display: contents;
}
.terms-conditions-text20 {
  display: inline-block;
}
.terms-conditions-fragment17 {
  display: contents;
}
.terms-conditions-text21 {
  display: inline-block;
}
.terms-conditions-fragment18 {
  display: contents;
}
.terms-conditions-text22 {
  display: inline-block;
}
.terms-conditions-fragment19 {
  display: contents;
}
.terms-conditions-text23 {
  display: inline-block;
  font-size: 23px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
.terms-conditions-fragment20 {
  display: contents;
}
.terms-conditions-text24 {
  display: inline-block;
  font-weight: 700;
  padding-bottom: var(--dl-space-space-halfunit);
}
.terms-conditions-fragment21 {
  display: contents;
}
.terms-conditions-text25 {
  display: inline-block;
}
.terms-conditions-fragment22 {
  display: contents;
}
.terms-conditions-text26 {
  display: inline-block;
}
.terms-conditions-fragment23 {
  display: contents;
}
.terms-conditions-text27 {
  display: inline-block;
}
.terms-conditions-fragment24 {
  display: contents;
}
.terms-conditions-content1 {
  display: inline-block;
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
}
.terms-conditions-fragment25 {
  display: contents;
}
.terms-conditions-content2 {
  display: inline-block;
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
}
.terms-conditions-text31 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
.terms-conditions-link1 {
  text-decoration: underline;
}
.terms-conditions-link2 {
  text-decoration: underline;
}
.terms-conditions-fragment26 {
  display: contents;
}
.terms-conditions-content3 {
  display: inline-block;
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
}
.terms-conditions-text33 {
  font-weight: 700;
}
.terms-conditions-fragment27 {
  display: contents;
}
.terms-conditions-text36 {
  display: inline-block;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  text-transform: capitalize;
}
@media(max-width: 1200px) {
  .terms-conditions-text11 {
    color: var(--dl-color-scheme-celadon);
  }
  .terms-conditions-layout302 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 991px) {
  .terms-conditions-max-width1 {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .terms-conditions-content4 {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .terms-conditions-image {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .terms-conditions-layout302 {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .terms-conditions-title1 {
    text-align: center;
  }
  .terms-conditions-iframe {
    width: 100%;
    height: 636px;
  }
}
@media(max-width: 767px) {
  .terms-conditions-max-width1 {
    flex-direction: column-reverse;
  }
  .terms-conditions-content4 {
    width: 100%;
    margin-right: 0px;
  }
  .terms-conditions-image {
    display: none;
  }
  .terms-conditions-content6 {
    align-items: flex-start;
  }
  .terms-conditions-title1 {
    text-align: left;
  }
  .terms-conditions-description1 {
    text-align: left;
  }
  .terms-conditions-text25 {
    text-align: center;
  }
  .terms-conditions-content3 {
    text-align: center;
    padding-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .terms-conditions-max-width2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .terms-conditions-content3 {
    text-align: center;
  }
}
</style>
