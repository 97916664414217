<template>
  <div class="faq-section section-container">
    <div class="faq-max-width max-width">
      <div class="faqfaq">
        <div class="faq-questions">
          <span class="beforeHeading">
            <slot name="text2">
              <!--Default content for text2-->
              <div class="faq-fragment19">
                <span class="faq-text32 beforeHeading">
                  lEARN MORE ABOUT PLOTPOINT 
                </span>
              </div>
            </slot>
          </span>
          <h1>
            <slot name="heading1">
              <!--Default content for heading1-->
              <div class="faq-fragment17">
                <h1 class="faq-text27">
                  <span class="faq-text28">
                    Frequently Asked
                    <span v-html="raw6qxz"></span>
                  </span>
                  <br />
                  <span class="faq-text30">Questions</span>
                </h1>
              </div>
            </slot>
          </h1>
          <div data-role="Accordion" class="question">
            <div data-type="accordion-header" class="faq-trigger1">
              <span class="faq-text12">
                <slot name="text3">
                  <!--Default content for text3-->
                  <div class="faq-fragment14">
                    <span class="faq-text24">
                      How does PlotPoint match originators with funders?
                    </span>
                  </div>
                </slot>
              </span>
              <svg viewBox="0 0 1024 1024" class="faq-icon1">
                <path
                  d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"
                ></path>
              </svg>
            </div>
            <div
              data-type="accordion-content"
              class="question-content faq-answer1"
            >
              <span>
                <slot name="text4">
                  <!--Default content for text4-->
                  <div class="faq-fragment18">
                    <span class="faq-text31">
                      PlotPoint uses a proprietary algorithm and thorough due
                      diligence process to connect loan originators with suitable
                      funders. Our team and platform evaluates project details,
                      risk appetite, and funding preferences to ensure the best
                      possible match, delivering quick and efficient connections.
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
          <div data-role="Accordion" class="question">
            <div data-type="accordion-header" class="faq-trigger2">
              <span>
                <slot name="text5">
                  <!--Default content for text5-->
                  <div class="faq-fragment16">
                    <span class="faq-text26">
                      What types of loans do we offer on the platform?
                    </span>
                  </div>
                </slot>
              </span>
              <svg viewBox="0 0 1024 1024" class="faq-icon3">
                <path
                  d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"
                ></path>
              </svg>
            </div>
            <div data-type="accordion-content" class="question-content">
              <span>
                <slot name="text6">
                  <!--Default content for text6-->
                  <div class="faq-fragment10">
                    <span class="faq-text20">
                      PlotPoint focuses on property-backed loans, including
                      bridging finance and development finance. These loans are
                      secured against real assets, offering both originators and
                      funders security and flexibility in their financial
                      projects.
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
          <div data-role="Accordion" class="question">
            <div data-type="accordion-header" class="faq-trigger3">
              <span>
                <slot name="text7">
                  <!--Default content for text7-->
                  <div class="faq-fragment13">
                    <span class="faq-text23">Is my investment secure?</span>
                  </div>
                </slot>
              </span>
              <svg viewBox="0 0 1024 1024" class="faq-icon5">
                <path
                  d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"
                ></path>
              </svg>
            </div>
            <div data-type="accordion-content" class="question-content">
              <span>
                <slot name="text8">
                  <!--Default content for text8-->
                  <div class="faq-fragment12">
                    <span class="faq-text22">
                      While all investments on PlotPoint are secured by real,
                      tangible assets and undergo rigorous due diligence—including
                      property valuations and borrower assessments—your capital is
                      at risk and not protected by the FSCS scheme. There is a
                      possibility of losing some or all of your principal and
                      interest.
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
          <div data-role="Accordion" class="question">
            <div data-type="accordion-header" class="faq-trigger4">
              <span>
                <slot name="text9">
                  <!--Default content for text9-->
                  <div class="faq-fragment15">
                    <span class="faq-text25">
                      Do you have an iOS or Android app?
                    </span>
                  </div>
                </slot>
              </span>
              <svg viewBox="0 0 1024 1024" class="faq-icon7">
                <path
                  d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"
                ></path>
              </svg>
            </div>
            <div data-type="accordion-content" class="question-content">
              <span>
                <slot name="text10">
                  <!--Default content for text10-->
                  <div class="faq-fragment11">
                    <span class="faq-text21">
                      Yes! PlotPoint offers a mobile app available on both iOS and
                      Android, allowing you to monitor your investments or loans
                      in real time, anytime, and anywhere.
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
        </div>
        <img
          alt="People reviewing notes"
          src="/common/faq-background-600w.png"
          loading="lazy"
          class="faq-image"
        />
      </div>
      <cta-banner-both
        rootClassName="cta-banner-bothroot-class-name"
      ></cta-banner-both>
    </div>
  </div>
</template>

<script>
import CtaBannerBoth from './cta-banner-both'

export default {
  name: 'FAQ',
  props: {},
  components: {
    CtaBannerBoth,
  },
  data() {
    return {
      rawn8eq: ' ',
      raw6qxz: ' ',
    }
  },
}
</script>

<style scoped>
.faq-section {
  position: relative;
  padding-top: 0px;
  padding-bottom: var(--dl-space-space-twounits);
}
.faq-max-width {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.faqfaq {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.faq-questions {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
}
.faq-trigger1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faq-icon1 {
  width: 12px;
  height: 12px;
}
.faq-trigger2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faq-icon3 {
  width: 12px;
  height: 12px;
}
.faq-trigger3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faq-icon5 {
  width: 12px;
  height: 12px;
}
.faq-trigger4 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faq-icon7 {
  width: 12px;
  height: 12px;
}
.faq-image {
  width: 50%;
  object-fit: cover;
  margin-right: -200px;
}
.faq-fragment10 {
  display: contents;
}
.faq-text20 {
  display: inline-block;
  margin-top: 8px;
}
.faq-fragment11 {
  display: contents;
}
.faq-text21 {
  display: inline-block;
  margin-top: 8px;
}
.faq-fragment12 {
  display: contents;
}
.faq-text22 {
  display: inline-block;
  margin-top: 8px;
}
.faq-fragment13 {
  display: contents;
}
.faq-text23 {
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.5;
}
.faq-fragment14 {
  display: contents;
}
.faq-text24 {
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.5;
}
.faq-fragment15 {
  display: contents;
}
.faq-text25 {
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.5;
}
.faq-fragment16 {
  display: contents;
}
.faq-text26 {
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.5;
}
.faq-fragment17 {
  display: contents;
}
.faq-text27 {
  width: 413px;
  display: inline-block;
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.faq-text28 {
  font-family: "Urbanist";
}
.faq-text30 {
  color: var(--dl-color-scheme-celadon);
  font-family: "Urbanist";
}
.faq-fragment18 {
  display: contents;
}
.faq-text31 {
  display: inline-block;
  margin-top: 8px;
}
.faq-fragment19 {
  display: contents;
}
.faq-text32 {
  color: var(--dl-color-scheme-green80);
  display: inline-block;
  padding-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 1200px) {
  .faq-text12 {
    font-size: 20px;
    font-style: normal;
    font-family: Urbanist;
    font-weight: 600;
    line-height: 1.5;
  }
  .faq-answer1 {
    height: auto;
    display: flex;
  }
  .faq-text24 {
    font-size: 20px;
    font-style: normal;
    font-family: Urbanist;
    font-weight: 600;
    line-height: 1.5;
  }
}
@media(max-width: 991px) {
  .faq-max-width {
    flex-direction: column;
  }
  .faq-questions {
    width: 60%;
  }
  .faq-image {
    margin-right: -100px;
  }
}
@media(max-width: 767px) {
  .faqfaq {
    position: relative;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
    justify-content: center;
  }
  .faq-questions {
    width: 100%;
    z-index: 100;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .faq-image {
    top: -3px;
    right: 69px;
    width: 407px;
    height: 407px;
    z-index: 1;
    position: absolute;
  }
}
@media(max-width: 479px) {
  .faqfaq {
    position: relative;
  }
  .faq-questions {
    z-index: 100;
  }
  .faq-image {
    top: 0px;
    right: 0px;
    width: auto;
    height: var(--dl-size-size-xlarge);
    opacity: 0.6;
    z-index: 1;
    position: absolute;
  }
}
</style>
