<template>
  <div class="page-not-found-container1">
    <app-header></app-header>
    <div class="page-not-found-hero hero-container section-container">
      <div class="page-not-found-max-width1 max-width">
        <div class="page-not-found-content">
          <span class="page-not-found-subtitle">ERROR 404</span>
          <h1 class="page-not-found-title">
            <span>Page not </span>
            <span class="page-not-found-text11">Found</span>
          </h1>
          <span class="page-not-found-description">
            Apologies, it appears that we could not find the page you were looking
            for. 
          </span>
          <div class="page-not-found-container2">
            <router-link
              to="/"
              class="page-not-found-navlink button button-gradient"
            >
              Go to Home
            </router-link>
          </div>
        </div>
        <div class="page-not-found-image1">
          <img
            alt="image"
            src="/common/union-400h.png"
            class="page-not-found-graphic-top"
          />
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="page-not-found-image2"
          />
        </div>
      </div>
    </div>
    <div class="page-not-found-section section-container">
      <div class="page-not-found-max-width2 max-width">
        <cta-banner-both></cta-banner-both>
      </div>
    </div>
    <app-footer>
      <template v-slot:text>
        <div class="page-not-found-fragment10">
          <span class="page-not-found-text12">Product</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="page-not-found-fragment11">
          <span class="page-not-found-text13">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="page-not-found-fragment12">
          <span class="page-not-found-text14">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="page-not-found-fragment13">
          <span class="page-not-found-text15">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="page-not-found-fragment14">
          <span class="page-not-found-text16">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="page-not-found-fragment15">
          <span class="page-not-found-text17">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="page-not-found-fragment16">
          <span class="page-not-found-text18">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="page-not-found-fragment17">
          <span class="page-not-found-text19">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="page-not-found-fragment18">
          <span class="page-not-found-text20">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="page-not-found-fragment19">
          <span class="page-not-found-text21 thq-body-small">
            Your Capital is at Risk
          </span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="page-not-found-fragment20">
          <span class="page-not-found-text22">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="page-not-found-fragment21">
          <span class="page-not-found-text23">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="page-not-found-fragment22">
          <span class="page-not-found-text24">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="page-not-found-fragment23">
          <span class="page-not-found-text25">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="page-not-found-fragment24">
          <span class="page-not-found-content1 thq-body-small">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="page-not-found-fragment25">
          <span class="page-not-found-content2 thq-body-small">
            <span class="page-not-found-text29">Phone: </span>
            <a href="tel:+442081547035" class="page-not-found-link1">+</a>
            <a href="tel:+442081547035" class="page-not-found-link2">
              44 208 154 7035
            </a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="page-not-found-fragment26">
          <span class="page-not-found-content3">
            <span class="page-not-found-text31">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawup4f"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="page-not-found-fragment27">
          <span class="page-not-found-text34 thq-body-small">
            PlotPoint Limited
          </span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import CtaBannerBoth from '../components/cta-banner-both'
import AppFooter from '../components/footer'

export default {
  name: 'PageNotFound',
  props: {},
  components: {
    AppHeader,
    CtaBannerBoth,
    AppFooter,
  },
  data() {
    return {
      rawup4f: ' ',
    }
  },
  metaInfo: {
    title: 'Page-Not-Found - PlotPoint',
    meta: [
      {
        property: 'og:title',
        content: 'Page-Not-Found - PlotPoint',
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
}
</script>

<style scoped>
.page-not-found-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.page-not-found-hero {
  padding-bottom: var(--dl-space-space-twounits);
}
.page-not-found-max-width1 {
  height: 668px;
  align-items: center;
}
.page-not-found-content {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.page-not-found-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
.page-not-found-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-unit);
}
.page-not-found-text11 {
  color: var(--dl-color-scheme-celadon);
}
.page-not-found-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
  padding-bottom: var(--dl-space-space-twounits);
}
.page-not-found-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.page-not-found-navlink {
  text-decoration: none;
}
.page-not-found-image1 {
  position: relative;
  padding-bottom: var(--dl-space-space-fourunits);
}
.page-not-found-graphic-top {
  top: 0px;
  right: -170px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
.page-not-found-image2 {
  right: -170px;
  bottom: 0px;
  position: absolute;
  object-fit: contain;
}
.page-not-found-section {
  position: relative;
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
}
.page-not-found-max-width2 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.page-not-found-fragment10 {
  display: contents;
}
.page-not-found-text12 {
  display: inline-block;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.page-not-found-fragment11 {
  display: contents;
}
.page-not-found-text13 {
  display: inline-block;
  margin-bottom: 0px;
}
.page-not-found-fragment12 {
  display: contents;
}
.page-not-found-text14 {
  display: inline-block;
}
.page-not-found-fragment13 {
  display: contents;
}
.page-not-found-text15 {
  display: inline-block;
}
.page-not-found-fragment14 {
  display: contents;
}
.page-not-found-text16 {
  display: inline-block;
}
.page-not-found-fragment15 {
  display: contents;
}
.page-not-found-text17 {
  display: inline-block;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.page-not-found-fragment16 {
  display: contents;
}
.page-not-found-text18 {
  display: inline-block;
}
.page-not-found-fragment17 {
  display: contents;
}
.page-not-found-text19 {
  display: inline-block;
}
.page-not-found-fragment18 {
  display: contents;
}
.page-not-found-text20 {
  display: inline-block;
}
.page-not-found-fragment19 {
  display: contents;
}
.page-not-found-text21 {
  display: inline-block;
  font-size: 23px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
.page-not-found-fragment20 {
  display: contents;
}
.page-not-found-text22 {
  display: inline-block;
  font-weight: 700;
  padding-bottom: var(--dl-space-space-halfunit);
}
.page-not-found-fragment21 {
  display: contents;
}
.page-not-found-text23 {
  display: inline-block;
}
.page-not-found-fragment22 {
  display: contents;
}
.page-not-found-text24 {
  display: inline-block;
}
.page-not-found-fragment23 {
  display: contents;
}
.page-not-found-text25 {
  display: inline-block;
}
.page-not-found-fragment24 {
  display: contents;
}
.page-not-found-content1 {
  display: inline-block;
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
}
.page-not-found-fragment25 {
  display: contents;
}
.page-not-found-content2 {
  display: inline-block;
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
}
.page-not-found-text29 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
.page-not-found-link1 {
  text-decoration: underline;
}
.page-not-found-link2 {
  text-decoration: underline;
}
.page-not-found-fragment26 {
  display: contents;
}
.page-not-found-content3 {
  display: inline-block;
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
}
.page-not-found-text31 {
  font-weight: 700;
}
.page-not-found-fragment27 {
  display: contents;
}
.page-not-found-text34 {
  display: inline-block;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  text-transform: capitalize;
}
@media(max-width: 1200px) {
  .page-not-found-text11 {
    color: var(--dl-color-scheme-celadon);
  }
}
@media(max-width: 991px) {
  .page-not-found-content {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .page-not-found-image1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .page-not-found-max-width2 {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .page-not-found-max-width1 {
    flex-direction: column-reverse;
  }
  .page-not-found-content {
    width: 100%;
    margin-right: 0px;
  }
  .page-not-found-text23 {
    text-align: center;
  }
  .page-not-found-content3 {
    text-align: center;
    padding-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .page-not-found-content3 {
    text-align: center;
  }
}
</style>
