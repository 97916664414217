<template>
  <div class="projects-container">
    <app-header></app-header>
    <div class="projects-hero hero-container section-container">
      <div class="projects-max-width max-width">
        <div class="projects-content">
          <span class="projects-subtitle">OUR EXPERIENCE</span>
          <h1 class="projects-title">
            <span>Past and Current </span>
            <span class="projects-text11">Projects</span>
          </h1>
        </div>
        <div class="projects-image"></div>
      </div>
    </div>
    <app-projects1 text115="First Charge">
      <template v-slot:text>
        <div class="projects-fragment10">
          <span class="projects-text12">Bridge loan in ruislip</span>
        </div>
      </template>
      <template v-slot:heading>
        <div class="projects-fragment11">
          <span class="projects-text13">Recent Deals</span>
        </div>
      </template>
    </app-projects1>
    <app-faq>
      <template v-slot:text2>
        <div class="projects-fragment12">
          <span class="projects-text14 beforeHeading">
            lEARN MORE ABOUT PLOTPOINT 
          </span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="projects-fragment13">
          <span class="projects-text15">
            How does PlotPoint match originators with funders?
          </span>
        </div>
      </template>
      <template v-slot:text4>
        <div class="projects-fragment14">
          <span class="projects-text16">
            PlotPoint uses a proprietary algorithm and thorough due diligence
            process to connect loan originators with suitable funders. Our team
            and platform evaluates project details, risk appetite, and funding
            preferences to ensure the best possible match, delivering quick and
            efficient connections.
          </span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="projects-fragment15">
          <span class="projects-text17">
            What types of loans do we offer on the platform?
          </span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="projects-fragment16">
          <span class="projects-text18">
            PlotPoint focuses on property-backed loans, including bridging finance
            and development finance. These loans are secured against real assets,
            offering both originators and funders security and flexibility in
            their financial projects.
          </span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="projects-fragment17">
          <span class="projects-text19">Is my investment secure?</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="projects-fragment18">
          <span class="projects-text20">
            While all investments on PlotPoint are secured by real, tangible
            assets and undergo rigorous due diligence—including property
            valuations and borrower assessments—your capital is at risk and not
            protected by the FSCS scheme. There is a possibility of losing some or
            all of your principal and interest.
          </span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="projects-fragment19">
          <span class="projects-text21">Do you have an iOS or Android app?</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="projects-fragment20">
          <span class="projects-text22">
            Yes! PlotPoint offers a mobile app available on both iOS and Android,
            allowing you to monitor your investments or loans in real time,
            anytime, and anywhere.
          </span>
        </div>
      </template>
      <template v-slot:heading1>
        <div class="projects-fragment21">
          <span class="projects-text23">
            <span class="projects-text24">
              Frequently Asked
              <span v-html="raw3671"></span>
            </span>
            <br />
            <span class="projects-text26">Questions</span>
          </span>
        </div>
      </template>
    </app-faq>
    <app-footer>
      <template v-slot:text>
        <div class="projects-fragment22">
          <span class="projects-text27">Product</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="projects-fragment23">
          <span class="projects-text28">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="projects-fragment24">
          <span class="projects-text29">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="projects-fragment25">
          <span class="projects-text30">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="projects-fragment26">
          <span class="projects-text31">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="projects-fragment27">
          <span class="projects-text32">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="projects-fragment28">
          <span class="projects-text33">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="projects-fragment29">
          <span class="projects-text34">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="projects-fragment30">
          <span class="projects-text35">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="projects-fragment31">
          <span class="projects-text36 thq-body-small">
            Your Capital is at Risk
          </span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="projects-fragment32">
          <span class="projects-text37">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="projects-fragment33">
          <span class="projects-text38">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="projects-fragment34">
          <span class="projects-text39">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="projects-fragment35">
          <span class="projects-text40">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="projects-fragment36">
          <span class="projects-content1 thq-body-small">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="projects-fragment37">
          <span class="projects-content2 thq-body-small">
            <span class="projects-text44">Phone: </span>
            <a href="tel:+442081547035" class="projects-link1">+</a>
            <a href="tel:+442081547035" class="projects-link2">44 208 154 7035</a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="projects-fragment38">
          <span class="projects-content3">
            <span class="projects-text46">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="raw5dte"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="projects-fragment39">
          <span class="projects-text49 thq-body-small">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppProjects1 from '../components/projects1'
import AppFaq from '../components/faq'
import AppFooter from '../components/footer'

export default {
  name: 'Projects',
  props: {},
  components: {
    AppHeader,
    AppProjects1,
    AppFaq,
    AppFooter,
  },
  data() {
    return {
      raw3671: ' ',
      raw5dte: ' ',
    }
  },
  metaInfo: {
    title: 'Our Projects - PlotPoint',
    meta: [
      {
        property: 'og:title',
        content: 'Our Projects - PlotPoint',
      },
    ],
  },
}
</script>

<style scoped>
.projects-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.projects-hero {
  padding-bottom: 0px;
}
.projects-max-width {
  align-items: center;
}
.projects-content {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.projects-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
.projects-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-unit);
}
.projects-text11 {
  color: var(--dl-color-scheme-celadon);
}
.projects-image {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  position: relative;
  padding-bottom: var(--dl-space-space-fourunits);
}
.projects-fragment10 {
  display: contents;
}
.projects-text12 {
  display: inline-block;
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
.projects-fragment11 {
  display: contents;
}
.projects-text13 {
  display: inline-block;
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}
.projects-fragment12 {
  display: contents;
}
.projects-text14 {
  color: var(--dl-color-scheme-green80);
  display: inline-block;
  padding-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.projects-fragment13 {
  display: contents;
}
.projects-text15 {
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.5;
}
.projects-fragment14 {
  display: contents;
}
.projects-text16 {
  display: inline-block;
  margin-top: 8px;
}
.projects-fragment15 {
  display: contents;
}
.projects-text17 {
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.5;
}
.projects-fragment16 {
  display: contents;
}
.projects-text18 {
  display: inline-block;
  margin-top: 8px;
}
.projects-fragment17 {
  display: contents;
}
.projects-text19 {
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.5;
}
.projects-fragment18 {
  display: contents;
}
.projects-text20 {
  display: inline-block;
  margin-top: 8px;
}
.projects-fragment19 {
  display: contents;
}
.projects-text21 {
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.5;
}
.projects-fragment20 {
  display: contents;
}
.projects-text22 {
  display: inline-block;
  margin-top: 8px;
}
.projects-fragment21 {
  display: contents;
}
.projects-text23 {
  width: 413px;
  display: inline-block;
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.projects-text24 {
  font-family: "Urbanist";
}
.projects-text26 {
  color: var(--dl-color-scheme-celadon);
  font-family: "Urbanist";
}
.projects-fragment22 {
  display: contents;
}
.projects-text27 {
  display: inline-block;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.projects-fragment23 {
  display: contents;
}
.projects-text28 {
  display: inline-block;
  margin-bottom: 0px;
}
.projects-fragment24 {
  display: contents;
}
.projects-text29 {
  display: inline-block;
}
.projects-fragment25 {
  display: contents;
}
.projects-text30 {
  display: inline-block;
}
.projects-fragment26 {
  display: contents;
}
.projects-text31 {
  display: inline-block;
}
.projects-fragment27 {
  display: contents;
}
.projects-text32 {
  display: inline-block;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.projects-fragment28 {
  display: contents;
}
.projects-text33 {
  display: inline-block;
}
.projects-fragment29 {
  display: contents;
}
.projects-text34 {
  display: inline-block;
}
.projects-fragment30 {
  display: contents;
}
.projects-text35 {
  display: inline-block;
}
.projects-fragment31 {
  display: contents;
}
.projects-text36 {
  display: inline-block;
  font-size: 23px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
.projects-fragment32 {
  display: contents;
}
.projects-text37 {
  display: inline-block;
  font-weight: 700;
  padding-bottom: var(--dl-space-space-halfunit);
}
.projects-fragment33 {
  display: contents;
}
.projects-text38 {
  display: inline-block;
}
.projects-fragment34 {
  display: contents;
}
.projects-text39 {
  display: inline-block;
}
.projects-fragment35 {
  display: contents;
}
.projects-text40 {
  display: inline-block;
}
.projects-fragment36 {
  display: contents;
}
.projects-content1 {
  display: inline-block;
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
}
.projects-fragment37 {
  display: contents;
}
.projects-content2 {
  display: inline-block;
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
}
.projects-text44 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
.projects-link1 {
  text-decoration: underline;
}
.projects-link2 {
  text-decoration: underline;
}
.projects-fragment38 {
  display: contents;
}
.projects-content3 {
  display: inline-block;
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
}
.projects-text46 {
  font-weight: 700;
}
.projects-fragment39 {
  display: contents;
}
.projects-text49 {
  display: inline-block;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  text-transform: capitalize;
}
@media(max-width: 1200px) {
  .projects-text11 {
    color: var(--dl-color-scheme-celadon);
  }
  .projects-text15 {
    font-size: 20px;
    font-style: normal;
    font-family: Urbanist;
    font-weight: 600;
    line-height: 1.5;
  }
}
@media(max-width: 991px) {
  .projects-content {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .projects-image {
    padding-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .projects-max-width {
    flex-direction: column-reverse;
  }
  .projects-content {
    width: 100%;
    margin-right: 0px;
  }
  .projects-image {
    display: none;
  }
  .projects-text38 {
    text-align: center;
  }
  .projects-content3 {
    text-align: center;
    padding-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .projects-content3 {
    text-align: center;
  }
}
</style>
