<template>
  <div class="disclaimer-container">
    <app-header></app-header>
    <div class="disclaimer-hero hero-container section-container">
      <div class="disclaimer-max-width1 max-width">
        <div class="disclaimer-content4">
          <span class="disclaimer-subtitle">risks disclosure</span>
          <h1 class="disclaimer-title">
            <span>Your Capital is </span>
            <span class="disclaimer-text11">at Risk</span>
          </h1>
        </div>
        <div class="disclaimer-image"></div>
      </div>
    </div>
    <div class="disclaimer-layout302 thq-section-padding">
      <div class="disclaimer-max-width2 thq-section-max-width">
        <div class="disclaimer-content5">
          <div class="disclaimer-content6 thq-flex-column">
            <h3 class="thq-heading-3 disclaimer-title11">
              Essential Risk Information for Funders
            </h3>
            <span class="disclaimer-description11 thq-body-small">
              <span>
                Private Credit opportunities offered through PlotPoint’s platform
                are considered complex and high-risk by their nature. Funders must
                understand the potential for significant losses, including the
                complete loss of capital.
              </span>
              <br />
              <br />
              <span class="disclaimer-text15">Key Risks to Consider</span>
              <br />
              <span class="disclaimer-text17">
                1) Risk of Losing Your Investment
              </span>
              <br />
              <span>
                When you fund an opportunity through PlotPoint, you are loaning
                money to a Special Purpose Vehicle (SPV), a subsidiary property
                company dedicated to the specific opportunity. These companies are
                created solely for this purpose and do not engage in ongoing
                business operations. If the borrower defaults or the SPV
                underperforms, you may lose some or all of your capital.
              </span>
              <br />
              <br />
              <span class="disclaimer-text22">
                2) Advertised Returns Are Not Guaranteed
              </span>
              <br />
              <span>
                The projected returns are not guaranteed, and this is not a
                savings account. A higher promised return often means higher risk.
                If the borrower does not repay as agreed, you could receive lower
                returns than expected or lose your initial capital entirely. If an
                offer appears too good to be true, it likely is.
              </span>
              <br />
              <br />
              <span class="disclaimer-text27">3) Limited Protections</span>
              <br />
              <span>
                Private Credit opportunities through PlotPoint do not fall under
                the protection of the Financial Services Compensation Scheme
                (FSCS). The FSCS does not cover losses related to poor
                performance, and compensation is not available for high-risk
                investment failures. Similarly, the Financial Ombudsman Service
                (FOS) does not cover poor performance claims.
                <span v-html="rawcpgb"></span>
              </span>
              <br />
              <br class="disclaimer-text31" />
              <span class="disclaimer-text32">4) Illiquid Investments</span>
              <br />
              <span>
                Private Credit opportunities facilitated by PlotPoint may not be
                easily liquidated. You must be prepared to hold these investments
                for their full term. While PlotPoint may offer a secondary market
                service to facilitate the sale of investments, there is no
                guarantee of finding a buyer. We may charge a fee for this
                service. You should carefully consider your liquidity needs before
                investing.
              </span>
              <br />
              <br />
              <span class="disclaimer-text37">5) Complex Structures</span>
              <br />
              <span>
                Private Credit involve loans to property developers. Developers
                may misuse funds, fail to complete projects, or encounter
                financial difficulties, which can lead to default and loss of your
                investment. The complexity of these projects makes it difficult to
                accurately assess the risks, though they are generally high.
              </span>
              <br />
              <br />
              <span class="disclaimer-text42">6) Diversify Your Portfolio</span>
              <br />
              <span>
                Concentrating all your capital in one investment or business
                increases your risk. It is advisable to diversify your capital
                investments to reduce dependency on any one opportunity. A good
                rule is to limit high-risk investments to no more than 10% of your
                overall portfolio.
              </span>
            </span>
            <h3 class="thq-heading-3 disclaimer-title12">
              PlotPoint: Your Intermediary, Not Your Advisor
            </h3>
            <span class="disclaimer-description12 thq-body-small">
              <span>
                PlotPoint Limited acts solely as an intermediary between loan
                originators and potential funders. We do not provide financial,
                legal, tax, or investment advice. PlotPoint is not regulated by
                the Financial Conduct Authority (FCA) and does not operate as an
                investment advisor or promoter under Section 21 of the Financial
                Services and Markets Act 2000 (FSMA).
              </span>
              <br />
              <br />
              <span>
                Our role is to connect originators with funders through our
                platform. All decisions regarding participation in opportunities
                presented on PlotPoint are made independently by funders, and we
                strongly encourage funders to seek professional financial, legal,
                or tax advice before proceeding with any investment.
              </span>
              <br />
              <br />
              <span>
                By using PlotPoint’s platform and accepting our Funder Terms and
                Conditions, funders acknowledge and accept the risks associated
                with private credit and property-backed investments. These risks
                include, but are not limited to, the potential loss of capital, as
                well as the possibility that returns are not guaranteed.
              </span>
              <br />
              <br />
              <span class="disclaimer-text54">No FSCS or FOS Protection</span>
              <br />
              <span>
                Investments made through PlotPoint are not protected by the
                Financial Services Compensation Scheme (FSCS), nor are they
                subject to oversight by the Financial Ombudsman Service (FOS).
                PlotPoint is not liable for any direct or indirect losses arising
                from the use of information or opportunities on our platform. Past
                performance is not a reliable indicator of future results, and
                capital is always at risk.
              </span>
              <br />
              <br />
              <span class="disclaimer-text59">
                Due Diligence and No Guarantees
              </span>
              <br />
              <span>
                Property-backed investments inherently carry risk. Funders must
                conduct thorough due diligence before committing capital.
                PlotPoint does not guarantee the performance of any loans or
                projects listed on our platform. While we vet all listings in
                collaboration with originators, we do not endorse, warrant, or
                recommend specific opportunities.
              </span>
              <br />
              <br />
              <span class="disclaimer-text64">Disclaimer</span>
              <br />
              <span>
                The information provided on our platform is subject to change
                without notice. It is intended for informational purposes only and
                does not constitute professional advice. Users of the site accept
                that no warranties are made regarding the accuracy or completeness
                of the information provided.
              </span>
              <br />
              <br />
              <span>
                By accessing PlotPoint, you agree to these terms and acknowledge
                the risks involved in private credit opportunities.
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <app-footer>
      <template v-slot:text>
        <div class="disclaimer-fragment10">
          <span class="disclaimer-text70">Product</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="disclaimer-fragment11">
          <span class="disclaimer-text71">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="disclaimer-fragment12">
          <span class="disclaimer-text72">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="disclaimer-fragment13">
          <span class="disclaimer-text73">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="disclaimer-fragment14">
          <span class="disclaimer-text74">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="disclaimer-fragment15">
          <span class="disclaimer-text75">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="disclaimer-fragment16">
          <span class="disclaimer-text76">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="disclaimer-fragment17">
          <span class="disclaimer-text77">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="disclaimer-fragment18">
          <span class="disclaimer-text78">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="disclaimer-fragment19">
          <span class="disclaimer-text79 thq-body-small">
            Your Capital is at Risk
          </span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="disclaimer-fragment20">
          <span class="disclaimer-text80">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="disclaimer-fragment21">
          <span class="disclaimer-text81">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="disclaimer-fragment22">
          <span class="disclaimer-text82">Risk Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="disclaimer-fragment23">
          <span class="disclaimer-text83">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="disclaimer-fragment24">
          <span class="disclaimer-content1 thq-body-small">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="disclaimer-fragment25">
          <span class="disclaimer-content2 thq-body-small">
            <span class="disclaimer-text87">Phone: </span>
            <a href="tel:+442081547035" class="disclaimer-link1">+</a>
            <a href="tel:+442081547035" class="disclaimer-link2">
              44 208 154 7035
            </a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="disclaimer-fragment26">
          <span class="disclaimer-content3">
            <span class="disclaimer-text89">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawi56z"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="disclaimer-fragment27">
          <span class="disclaimer-text92 thq-body-small">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppFooter from '../components/footer'

export default {
  name: 'Disclaimer',
  props: {},
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      rawcpgb: ' ',
      rawi56z: ' ',
    }
  },
  metaInfo: {
    title: 'Disclaimer - Yubaro',
    meta: [
      {
        property: 'og:title',
        content: 'Disclaimer - Yubaro',
      },
    ],
  },
}
</script>

<style scoped>
.disclaimer-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.disclaimer-hero {
  padding-bottom: 0px;
}
.disclaimer-max-width1 {
  align-items: center;
}
.disclaimer-content4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.disclaimer-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
.disclaimer-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.2;
}
.disclaimer-text11 {
  color: var(--dl-color-scheme-celadon);
}
.disclaimer-image {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: none;
  position: relative;
  padding-bottom: var(--dl-space-space-fourunits);
}
.disclaimer-layout302 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.disclaimer-max-width2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.disclaimer-content5 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.disclaimer-content6 {
  align-items: flex-start;
}
.disclaimer-description11 {
  text-align: left;
  font-family: "Bricolage Grotesque";
}
.disclaimer-text15 {
  font-weight: 700;
}
.disclaimer-text17 {
  font-weight: 700;
}
.disclaimer-text22 {
  font-weight: 700;
}
.disclaimer-text27 {
  font-weight: 700;
}
.disclaimer-text31 {
  font-weight: 700;
}
.disclaimer-text32 {
  font-weight: 700;
}
.disclaimer-text37 {
  font-weight: 700;
}
.disclaimer-text42 {
  font-weight: 700;
}
.disclaimer-description12 {
  text-align: left;
  font-family: "Bricolage Grotesque";
}
.disclaimer-text54 {
  font-weight: 700;
}
.disclaimer-text59 {
  font-weight: 700;
}
.disclaimer-text64 {
  font-weight: 700;
}
.disclaimer-fragment10 {
  display: contents;
}
.disclaimer-text70 {
  display: inline-block;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.disclaimer-fragment11 {
  display: contents;
}
.disclaimer-text71 {
  display: inline-block;
  margin-bottom: 0px;
}
.disclaimer-fragment12 {
  display: contents;
}
.disclaimer-text72 {
  display: inline-block;
}
.disclaimer-fragment13 {
  display: contents;
}
.disclaimer-text73 {
  display: inline-block;
}
.disclaimer-fragment14 {
  display: contents;
}
.disclaimer-text74 {
  display: inline-block;
}
.disclaimer-fragment15 {
  display: contents;
}
.disclaimer-text75 {
  display: inline-block;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.disclaimer-fragment16 {
  display: contents;
}
.disclaimer-text76 {
  display: inline-block;
}
.disclaimer-fragment17 {
  display: contents;
}
.disclaimer-text77 {
  display: inline-block;
}
.disclaimer-fragment18 {
  display: contents;
}
.disclaimer-text78 {
  display: inline-block;
}
.disclaimer-fragment19 {
  display: contents;
}
.disclaimer-text79 {
  display: inline-block;
  font-size: 23px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
.disclaimer-fragment20 {
  display: contents;
}
.disclaimer-text80 {
  display: inline-block;
  font-weight: 700;
  padding-bottom: var(--dl-space-space-halfunit);
}
.disclaimer-fragment21 {
  display: contents;
}
.disclaimer-text81 {
  display: inline-block;
}
.disclaimer-fragment22 {
  display: contents;
}
.disclaimer-text82 {
  display: inline-block;
}
.disclaimer-fragment23 {
  display: contents;
}
.disclaimer-text83 {
  display: inline-block;
}
.disclaimer-fragment24 {
  display: contents;
}
.disclaimer-content1 {
  display: inline-block;
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
}
.disclaimer-fragment25 {
  display: contents;
}
.disclaimer-content2 {
  display: inline-block;
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
}
.disclaimer-text87 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
.disclaimer-link1 {
  text-decoration: underline;
}
.disclaimer-link2 {
  text-decoration: underline;
}
.disclaimer-fragment26 {
  display: contents;
}
.disclaimer-content3 {
  display: inline-block;
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
}
.disclaimer-text89 {
  font-weight: 700;
}
.disclaimer-fragment27 {
  display: contents;
}
.disclaimer-text92 {
  display: inline-block;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  text-transform: capitalize;
}
@media(max-width: 1200px) {
  .disclaimer-text11 {
    color: var(--dl-color-scheme-celadon);
  }
  .disclaimer-layout302 {
    padding: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 991px) {
  .disclaimer-max-width1 {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .disclaimer-content4 {
    width: 100%;
  }
  .disclaimer-image {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .disclaimer-title11 {
    text-align: center;
  }
  .disclaimer-title12 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .disclaimer-max-width1 {
    flex-direction: column-reverse;
  }
  .disclaimer-content4 {
    width: 100%;
    margin-right: 0px;
  }
  .disclaimer-image {
    display: none;
  }
  .disclaimer-content6 {
    align-items: flex-start;
  }
  .disclaimer-title11 {
    text-align: left;
  }
  .disclaimer-description11 {
    text-align: left;
  }
  .disclaimer-title12 {
    text-align: left;
  }
  .disclaimer-description12 {
    text-align: left;
  }
  .disclaimer-text81 {
    text-align: center;
  }
  .disclaimer-content3 {
    text-align: center;
    padding-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .disclaimer-image {
    display: none;
  }
  .disclaimer-max-width2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .disclaimer-content3 {
    text-align: center;
  }
}
</style>
