<template>
  <div class="cta-banner-both-banner" v-bind:class="rootClassName">
    <span class="cta-banner-both-text1 beforeHeading">get started</span>
    <h1 class="cta-banner-both-text2">
      <span>Take your strategy to </span>
      <span>the next level.</span>
    </h1>
    <span class="cta-banner-both-text5">
      <span>
        Join PlotPoint and connect with fully-vetted, asset-backed opportunities
        that deliver transparency, control, and peace of mind. Whether you’re a
        seasoned high net worth individual or a growing originator, our platform
        offers everything you need to succeed.
      </span>
      <span><span v-html="raw0s5t"></span></span>
    </span>
    <img :alt="imageAlt" :src="imageSrc" class="cta-banner-both-image1" />
    <div class="cta-banner-both-btns">
      <router-link
        to="/originators"
        class="cta-banner-both-navlink1 button button-transparent"
      >
        <span class="cta-banner-both-text8">Originator?  Learn More</span>
      </router-link>
      <router-link
        to="/coming-soon"
        class="cta-banner-both-navlink2 button button-gradient"
      >
        <span class="cta-banner-both-text9">Get started as a Funder</span>
      </router-link>
    </div>
    <img :alt="imageAlt" :src="imageSrc" class="cta-banner-both-image2" />
  </div>
</template>

<script>
export default {
  name: 'CTABannerBoth',
  props: {
    rootClassName: String,
    imageSrc: {
      type: String,
      default: '/common/group%2018-300h.png',
    },
    imageAlt: {
      type: String,
      default: 'image',
    },
  },
  data() {
    return {
      raw0s5t: ' ',
    }
  },
}
</script>

<style scoped>
.cta-banner-both-banner {
  clip: auto;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  position: relative;
  align-items: center;
  border-radius: 48px;
  flex-direction: column;
  background-color: var(--dl-color-scheme-green100);
}
.cta-banner-both-text1 {
  color: var(--dl-color-scheme-honeydew);
  margin-bottom: var(--dl-space-space-halfunit);
}
.cta-banner-both-text2 {
  color: var(--dl-color-scheme-white);
  z-index: 100;
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: var(--dl-space-space-halfunit);
}
.cta-banner-both-text5 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  width: auto;
  z-index: 100;
  text-align: center;
  padding-left: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: 0px;
}
.cta-banner-both-image1 {
  top: 33px;
  right: -2px;
  width: 195px;
  height: 275px;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
  object-fit: contain;
}
.cta-banner-both-btns {
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.cta-banner-both-navlink1 {
  font-size: 16px;
  text-decoration: none;
}
.cta-banner-both-navlink2 {
  text-align: center;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.cta-banner-both-image2 {
  top: 19px;
  left: 2px;
  width: 195px;
  height: 275px;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
  transform: rotate(180deg);
  object-fit: contain;
}

@media(max-width: 767px) {
  .cta-banner-both-banner {
    padding: var(--dl-space-space-twounits);
  }
  .cta-banner-both-image1 {
    top: 40px;
    right: 1px;
  }
  .cta-banner-both-navlink2 {
    z-index: 100;
  }
  .cta-banner-both-image2 {
    top: 2px;
    right: -166px;
  }
}
@media(max-width: 479px) {
  .cta-banner-both-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .cta-banner-both-text5 {
    line-height: 1.4;
  }
  .cta-banner-both-image1 {
    top: 143px;
    right: 1px;
  }
  .cta-banner-both-btns {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .cta-banner-both-navlink1 {
    z-index: 100;
    text-align: center;
    font-family: Bricolage Grotesque;
  }
  .cta-banner-both-text8 {
    font-size: 16px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
  }
  .cta-banner-both-navlink2 {
    z-index: 100;
    font-size: 16px;
    font-family: Bricolage Grotesque;
  }
  .cta-banner-both-text9 {
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 600;
  }
}
</style>
