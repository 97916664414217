<template>
  <div class="projects1-section section-container" v-bind:class="rootClassName">
    <div class="projects1-max-width max-width">
      <span class="beforeHeading">
        <slot name="text">
          <!--Default content for text-->
          <div><span>Bridge loan in ruislip</span></div>
        </slot>
      </span>
      <h1>
        <slot name="heading">
          <!--Default content for heading-->
          <div class="projects1-fragment2">
            <h1 class="projects1-text40">Recent Deals</h1>
          </div>
        </slot>
      </h1>
      <div class="projects1-cards-container">
        <div class="projects1-card1">
          <div class="projects1-info1">
            <span class="projects1-text12">
              <slot name="text">
                <!--Default content for text-->
                <div class="projects1-fragment1">
                  <span class="projects1-text39">Bridge loan in ruislip</span>
                </div>
              </slot>
            </span>
            <div class="projects1-target-amount1">
              <div class="projects1-row110">
                <span class="projects1-text13">Amount: </span>
              </div>
              <div class="projects1-row111">
                <span class="projects1-text14">£250,000</span>
              </div>
            </div>
            <div class="projects1-security-type1">
              <div class="projects1-row112">
                <span class="projects1-text15">{{ text1123 }}</span>
              </div>
              <div class="projects1-row113">
                <span class="projects1-text16">{{ text115 }}</span>
              </div>
            </div>
            <div class="projects1ltv1">
              <div class="projects1-row114">
                <span class="projects1-text17">{{ text112211 }}</span>
              </div>
              <div class="projects1-row115">
                <span class="projects1-text18">37%</span>
              </div>
            </div>
            <div class="projects1-interest1">
              <div class="projects1-row116">
                <span class="projects1-text19">{{ text11211111 }}</span>
              </div>
              <div class="projects1-row117">
                <span class="projects1-text20">
                  9% p.a
                  <span v-html="raw2kfj"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="projects1-card2">
          <div class="projects1-info2">
            <span class="projects1-text21">Bridge loan in watford</span>
            <div class="projects1-target-amount2">
              <div class="projects1-row118">
                <span class="projects1-text22">Amount: </span>
              </div>
              <div class="projects1-row119">
                <span class="projects1-text23">£1,150,000</span>
              </div>
            </div>
            <div class="projects1-security-type2">
              <div class="projects1-row120">
                <span class="projects1-text24">{{ text1123 }}</span>
              </div>
              <div class="projects1-row121">
                <span class="projects1-text25">First Charge</span>
              </div>
            </div>
            <div class="projects1ltv2">
              <div class="projects1-row122">
                <span class="projects1-text26">{{ text112211 }}</span>
              </div>
              <div class="projects1-row123">
                <span class="projects1-text27">67%</span>
              </div>
            </div>
            <div class="projects1-interest2">
              <div class="projects1-row124">
                <span class="projects1-text28">{{ text11211111 }}</span>
              </div>
              <div class="projects1-row125">
                <span class="projects1-text29">
                  9.9% p.a
                  <span v-html="rawm0fo"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="projects1-card3">
          <div class="projects1-info3">
            <span class="projects1-text30">development Bridge loan in acton</span>
            <div class="projects1-target-amount3">
              <div class="projects1-row126">
                <span class="projects1-text31">Amount: </span>
              </div>
              <div class="projects1-row127">
                <span class="projects1-text32">£630,000</span>
              </div>
            </div>
            <div class="projects1-security-type3">
              <div class="projects1-row128">
                <span class="projects1-text33">{{ text1123 }}</span>
              </div>
              <div class="projects1-row129">
                <span class="projects1-text34">First Charge</span>
              </div>
            </div>
            <div class="projects1ltv3">
              <div class="projects1-row130">
                <span class="projects1-text35">{{ text112211 }}</span>
              </div>
              <div class="projects1-row131">
                <span class="projects1-text36">70%</span>
              </div>
            </div>
            <div class="projects1-interest3">
              <div class="projects1-row132">
                <span class="projects1-text37">{{ text11211111 }}</span>
              </div>
              <div class="projects1-row133">
                <span class="projects1-text38">
                  9% p.a
                  <span v-html="raw2ff8"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Projects1',
  props: {
    text112211: {
      type: String,
      default: 'LTV:',
    },
    text115: {
      type: String,
      default: 'First Charge',
    },
    text11211111: {
      type: String,
      default: 'Interest',
    },
    rootClassName: String,
    text1123: {
      type: String,
      default: 'Security Type:',
    },
  },
  data() {
    return {
      raw2kfj: ' ',
      rawm0fo: ' ',
      raw2ff8: ' ',
    }
  },
}
</script>

<style scoped>
.projects1-section {
  position: relative;
  padding-top: var(--dl-space-space-fourunits);
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-celadon);
}
.projects1-max-width {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.projects1-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.projects1-card1 {
  flex: 0 0 auto;
  width: 32%;
  display: flex;
  padding: 20px;
  max-width: 450px;
  min-height: 480px;
  align-items: center;
  border-radius: 36px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/projects/ruislip2-500w.jpg");
}
.projects1-info1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-scheme-white);
}
.projects1-text12 {
  color: var(--dl-color-scheme-green100);
  font-size: 20px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.5;
}
.projects1-target-amount1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.projects1-row110 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text13 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
.projects1-row111 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text14 {
  font-weight: 400;
  line-height: 1.5;
}
.projects1-security-type1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.projects1-row112 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text15 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
.projects1-row113 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text16 {
  line-height: 1.5;
}
.projects1ltv1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.projects1-row114 {
  flex: 0 0 auto;
  width: 53%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text17 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
.projects1-row115 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text18 {
  line-height: 1.5;
}
.projects1-interest1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.projects1-row116 {
  flex: 0 0 auto;
  width: 53%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text19 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
.projects1-row117 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text20 {
  line-height: 1.5;
}
.projects1-card2 {
  flex: 0 0 auto;
  width: 32%;
  display: flex;
  padding: 20px;
  max-width: 450px;
  min-height: 480px;
  align-items: center;
  border-radius: 36px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/projects/watford1-500w.jpg");
}
.projects1-info2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-scheme-white);
}
.projects1-text21 {
  font-size: 20px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
.projects1-target-amount2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.projects1-row118 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text22 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
.projects1-row119 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text23 {
  font-weight: 400;
  line-height: 1.5;
}
.projects1-security-type2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.projects1-row120 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text24 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
.projects1-row121 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text25 {
  line-height: 1.5;
}
.projects1ltv2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.projects1-row122 {
  flex: 0 0 auto;
  width: 53%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text26 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
.projects1-row123 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text27 {
  line-height: 1.5;
}
.projects1-interest2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.projects1-row124 {
  flex: 0 0 auto;
  width: 53%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text28 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
.projects1-row125 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text29 {
  line-height: 1.5;
}
.projects1-card3 {
  flex: 0 0 auto;
  width: 32%;
  display: flex;
  padding: 20px;
  max-width: 450px;
  min-height: 480px;
  align-items: center;
  border-radius: 36px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/acton1-500w.jpg");
}
.projects1-info3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-scheme-white);
}
.projects1-text30 {
  font-size: 20px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
.projects1-target-amount3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.projects1-row126 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text31 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
.projects1-row127 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text32 {
  font-weight: 400;
  line-height: 1.5;
}
.projects1-security-type3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.projects1-row128 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text33 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
.projects1-row129 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text34 {
  line-height: 1.5;
}
.projects1ltv3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.projects1-row130 {
  flex: 0 0 auto;
  width: 53%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text35 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
.projects1-row131 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text36 {
  line-height: 1.5;
}
.projects1-interest3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.projects1-row132 {
  flex: 0 0 auto;
  width: 53%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text37 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
.projects1-row133 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projects1-text38 {
  line-height: 1.5;
}
.projects1-fragment1 {
  display: contents;
}
.projects1-text39 {
  display: inline-block;
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
.projects1-fragment2 {
  display: contents;
}
.projects1-text40 {
  display: inline-block;
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}

@media(max-width: 991px) {
  .projects1-max-width {
    flex-direction: column;
  }
  .projects1-text12 {
    color: var(--dl-color-scheme-green100);
    font-size: 20px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
    line-height: 1.5;
  }
  .projects1-text13 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text14 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text15 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text16 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text17 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .projects1-text18 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-row116 {
    width: 50%;
  }
  .projects1-text19 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .projects1-text20 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text22 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text23 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text24 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text25 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text26 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .projects1-text27 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-row124 {
    width: 50%;
  }
  .projects1-text28 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .projects1-text29 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text31 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text32 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text33 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text34 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-text35 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .projects1-text36 {
    font-size: 14px;
    line-height: 1.5;
  }
  .projects1-row132 {
    width: 50%;
  }
  .projects1-text37 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .projects1-text38 {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media(max-width: 767px) {
  .projects1-cards-container {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-wrap: wrap;
    justify-content: center;
  }
  .projects1-card1 {
    width: 100%;
  }
  .projects1-row114 {
    width: 50%;
  }
  .projects1-card2 {
    width: 100%;
  }
  .projects1-row122 {
    width: 50%;
  }
  .projects1-card3 {
    width: 100%;
  }
  .projects1-row130 {
    width: 50%;
  }
}
</style>
