<template>
  <div>
    <!-- Container for Turnstile widget -->
    <div id="cf-turnstile" ref="turnstile" class="cf-turnstile"></div>
  </div>
</template>

<script>
export default {
  name: 'TurnstileCaptcha',
  data() {
    return {
      turnstileToken: null, // To store the token generated by Turnstile
    };
  },
  mounted() {
    this.loadTurnstile();
  },
  methods: {
    loadTurnstile() {
      // Render Turnstile widget when the component is mounted
      if (typeof turnstile !== 'undefined') {
        turnstile.render(this.$refs.turnstile, {
          sitekey: '0x4AAAAAAAkNOnL-L6-s7dnK', // Replace with your actual site key
          callback: this.onTurnstileSuccess, // Callback when Turnstile is successful
          'error-callback': this.onTurnstileError, // Optional error callback
          'expired-callback': this.onTurnstileExpired, // Optional expiry callback
        });
      }
    },
    onTurnstileSuccess(token) {
      this.turnstileToken = token;
      console.log('Turnstile Token:', token);
      // You can now send this token as part of your form submission
    },
    onTurnstileError() {
      console.error('Turnstile error occurred');
    },
    onTurnstileExpired() {
      console.warn('Turnstile token expired');
      this.turnstileToken = null; // Clear the token when expired
    },
  },
};
</script>

<style scoped>
/* Optional styling for the Turnstile widget */
#cf-turnstile {
  margin: 20px 0;
}
</style>